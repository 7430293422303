@import "../../../node_modules/ol/ol.css";
@import "../../../node_modules/ui-select/dist/select.css";

@import "bootstrap/less/bootstrap";
@import "bootstrap-material-design/less/bootstrap-material-design.less";
@import "bootstrap-material-design/less/ripples.less";
@import "mdi/css/materialdesignicons.min.css";
@import "custom/variables";
@import "custom/colors";
@import "custom/redesign";

@import "partials/fonts/index";
@import "partials/layout/index";

h1,
.h1 {
  margin-bottom: 30px;
  margin-top: 10px;
  font-weight: 500;

  a[data-edit] {
    margin-left: 10px;
    font-size: 18px;
  }

  &.pull-left {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.uppercase {
  text-transform: uppercase;
}

.large {
  font-size: 30px;
}

.no-todos {
  text-align: center;
  padding: 40px 20px;

  p {
    margin: 0;
    padding-top: 10px;
  }
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

.valign-middle {
  display: table;
  width: 100%;
}

.valign-middle-row {
  float: none;
  display: table-cell;
  vertical-align: middle;
}

// calendar styling -- START

.tooltip.calendar .tooltip-inner {
  color: #000000;
  background-color: inherit;
  min-width: 600px;
  text-align: left;
}

.tooltip.calendar .tooltip-arrow {
  display: none;
}

.tooltip.calendar .tooltip-inner .calendar-action-sum-container {
  float: right;
}

.tooltip.calendar .tooltip-inner .calendar-action-sum-container .area {
  min-width: 125px;
  display: inline-block;
  text-align: right;
}

p.calendar-menu {
  float: left;
}

.calendar-popup-head {
  line-height: 24px;
}

.calendar-popup-content .form-group {
  margin-bottom: 15px;
}

.calendar-event-confirmed {
  text-decoration: line-through;
}

.fc-sat,
.fc-sun {
  background-color: #fff2f2;
}

.fc-past {
  background-color: #ededed !important;
}

.fc-toolbar {
  margin-bottom: 0em;
}
.fc-toolbar h2:first-letter,
th.fc-day-header {
  text-transform: capitalize;
}

.fc-center h2 {
  font-size: 1.7em;
}

.fc-event {
  border: none;
  line-height: normal;
  font-size: 1em;
}

// calendar styling -- END

.classifiers-tabs {
  ul {
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
  }
}

.classifiers-heading {
  text-align: center;
}
