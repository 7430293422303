.progress {
    height: 4px;
    border-radius: 0;
    box-shadow: none;
    background: #c8c8c8;
    .progress-bar {
        box-shadow: none;
        .variations(~".progress-bar", ~"", background-color, @brand-primary);
    }
}
