/* // TODO check styles*/

#irrigation-map {
    background-color: grey
}

#irrigation-map .ol-zoom {
    left: unset;
    top: unset;
    right: 15px;
    bottom: 28px;
}

#irrigation-map .ol-attribution {
    font-size: 11px;
}

#irrigation-map .ol-control,
#irrigation-map .ol-control:hover {
    padding: 0px;
    background: none;
}

#irrigation-map .ol-overlaycontainer-stopevent .ol-zoom button,
#irrigation-map .ol-control button,
#irrigation-map .ol-control button:focus
{
    color: black;
    background-color: #FFFFFF;
    width: 32px;
    height: 32px;
    margin: 0px;
    border-radius: 4px;
}

#irrigation-map .ol-control button.ol-zoom-in {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

#irrigation-map .ol-control button.ol-zoom-out {
    height: 33px;
    border-top: 1px solid #e0e0e0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

#irrigation-map .ol-overlaycontainer-stopevent .ol-zoom button:hover,
#irrigation-map .ol-control button:hover {
    color: #00A179;
}
