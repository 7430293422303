@import '_inputs-size.less';

// label variations
.label {
  border-radius: @border-radius-small;
  .variations(~".label", ~"", background-color, @grey);
  padding: .3em .6em; // Make top & bottom .label padding the same: https://github.com/twbs/bootstrap/pull/19631
}

// must be broken out for reuse - webkit selector breaks firefox
.label-static(@label-top, @static-font-size, @static-line-height) {
  label.control-label {
    top: @label-top;
    left: 0;
    // must repeat because the selector above is more specific than the general label sizing
    font-size: @static-font-size;
    line-height: @static-line-height;
  }
}

.label-size-variant(@placeholder-font-size, @vertical-padding, @line-height, @static-font-size, @static-line-height, @help-block-font-size){
  .form-control {
    .material-placeholder({
      font-size: @placeholder-font-size;
      line-height: @line-height;
      color: @mdb-input-placeholder-color;
      font-weight: 400;
    });
    // margin-bottom must be specified to give help-block vertical space.
    //    @see also form-group padding-bottom (and size variants) re: collapsible margins.  These work together.
    margin-bottom: @vertical-padding;
  }

  // generic labels used anywhere in the form (not control-label)
  .checkbox label,
  .radio label,
  label {
    font-size: @placeholder-font-size;
    line-height: @line-height;
    color: @mdb-input-placeholder-color;
    font-weight: 400;
  }

  // smaller focused or static size
  label.control-label {
    font-size: @static-font-size;
    line-height: @static-line-height;
    font-weight: 400;
    margin: 16px 0 0 0; // std and lg
  }

  .help-block {
    margin-top: 0; // allow the input margin to set-off the top of the help-block
    font-size: @help-block-font-size;
  }
}

.form-group-validation-state(@name, @color) {

  &.@{name} { // e.g. has-error
    .form-control {
      box-shadow: none;
    }
    &.is-focused .form-control {
      background-image: linear-gradient(@color, @color), linear-gradient(@mdb-input-underline-color, @mdb-input-underline-color);
    }
    label.control-label,
    .help-block {
      color: @color;
    }
  }
}

.form-group-size-variant(@parent, @placeholder-font-size, @label-top-margin, @vertical-padding, @line-height, @label-as-placeholder-shim) {
  @static-font-size: ceil((@mdb-label-static-size-ratio * @placeholder-font-size));
  @static-line-height: (@mdb-label-static-size-ratio * @line-height);

  @label-as-placeholder-top: -1 * (@vertical-padding + @label-as-placeholder-shim);
  @label-top: @label-as-placeholder-top - (@placeholder-font-size + @vertical-padding);

  @help-block-font-size: ceil((@mdb-help-block-size-ratio * @placeholder-font-size));
  @help-block-line-height: (@mdb-help-block-size-ratio * @line-height);

  // this is outside a form-group
  & when not (isstring(@parent)) {
    .label-size-variant(@placeholder-font-size, @vertical-padding, @line-height, @static-font-size, @static-line-height, @help-block-font-size);
  }

  // this is inside a form-group, may be .form-group.form-group-sm or .form-group.form-group-lg
  & when (isstring(@parent)) {
    @{parent} {
      .label-size-variant(@placeholder-font-size, @vertical-padding, @line-height, @static-font-size, @static-line-height, @help-block-font-size);

      // form-group padding-bottom
      //  upon collapsing margins, the largest margin is honored which collapses the form-control margin-bottom,
      //  so the form-control margin-bottom must also be expressed as form-group padding
      padding-bottom: @vertical-padding;

      // form-group margin-top must be large enough for the label and the label's top padding since label is absolutely positioned
      margin: (@label-top-margin + @static-font-size) 0 0 0;

      // larger labels as placeholders
      &.label-floating,
      &.label-placeholder {
        label.control-label {
          top: @label-as-placeholder-top; // place the floating label to look like a placeholder with input padding
          font-size: @placeholder-font-size;
          line-height: @line-height;
        }
      }

      // static, focused, or autofill floating labels
      &.label-static,
      &.label-floating.is-focused,
      &.label-floating:not(.is-empty) {
        .label-static(@label-top, @static-font-size, @static-line-height);
      }
      // #559 Fix for webkit/chrome autofill - rule must be separate because it breaks firefox otherwise #731
      &.label-floating input.form-control:-webkit-autofill ~ label.control-label {
        .label-static(@label-top, @static-font-size, @static-line-height);
      }
    }
  }
}

// -----
// Inputs
//
// Reference http://www.google.com/design/spec/components/text-fields.html
// MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
.form-control,
.form-group .form-control {
  border: 0;
  background-image: linear-gradient(@brand-primary, @brand-primary), linear-gradient(@mdb-input-underline-color, @mdb-input-underline-color);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(~"100% - 1px");
  background-color: rgba(0, 0, 0, 0);
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;

  // Placeholders and and labels-as-placeholders should look the same
  .material-placeholder({
    color: @mdb-input-placeholder-color;
    font-weight: 400;
  });


  //&:textarea {    // appears to be an invalid selector
  //  height: 40px;
  //}

  &[readonly],
  &[disabled],
  fieldset[disabled] & {
    background-color: rgba(0, 0, 0, 0);
  }

  &[disabled],
  fieldset[disabled] & {
    background-image: none;
    border-bottom: 1px dotted @mdb-input-underline-color;
  }
}

// -----
// Labels with form-group signalled state
//
// Reference http://www.google.com/design/spec/components/text-fields.html
// MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
//.variations(~" label.control-label", color, @mdb-input-placeholder-color);  // default label color variations

.form-group {
  position: relative;

  // -----
  // Labels with form-group signalled state
  //
  // Reference http://www.google.com/design/spec/components/text-fields.html
  // MDL implementation: http://www.getmdl.io/components/index.html#textfields-section
  &.label-static,
  &.label-placeholder,
  &.label-floating {
    label.control-label {
      position: absolute;
      pointer-events: none;
      transition: 0.3s ease all;
    }
  }

  // hint to browser for optimization
  //    TODO: evaluate effectiveness - looking for community feedback
  &.label-floating label.control-label {
    will-change: left, top, contents;
  }

  // hide label-placeholders when the field is not empty
  &.label-placeholder:not(.is-empty){
    label.control-label{
      display: none;
    }
  }

  // Help blocks - position: absolute approach - uses no vertical space, text wrapping - not so good.
  .help-block {
    position: absolute; // do not use position: absolute because width/wrapping isn't automatic and overflows occur
    display: none;
  }

  // form-group is-focused display
  &.is-focused {
    .form-control {
      outline: none;
      background-image: linear-gradient(@brand-primary, @brand-primary), linear-gradient(@mdb-input-underline-color, @mdb-input-underline-color);
      background-size: 100% 2px, 100% 1px;
      box-shadow: none;
      transition-duration: 0.3s;

      .material-input:after {
        background-color: @brand-primary;
      }
    }

    //.variations(~".is-focused label.control-label", color, @brand-primary); // focused label color variations
    label,
    label.control-label {
      color: @brand-primary;
    }

    //.variations(~".is-focused.label-placeholder label.control-label", color, @mdb-input-placeholder-color);  // default label color variations
    &.label-placeholder {
      label,
      label.control-label {
        color: @mdb-input-placeholder-color;
      }
    }

    .help-block {
      display: block;
    }
  }

  .form-group-validation-state(has-warning, @brand-warning);
  .form-group-validation-state(has-error, @brand-danger);
  .form-group-validation-state(has-success, @brand-success);
  .form-group-validation-state(has-info, @brand-info);

  textarea {
    resize: none;
    & ~ .form-control-highlight {
      margin-top: -11px;
    }
  }

  select {
    appearance: none; // Fix for OS X

    & ~ .material-input:after {
      display: none;
    }
  }
}

// default floating size/location without a form-group (will skip form-group styles, and just render default sizing variation)
.form-group-size-variant(null, @mdb-input-font-size-base, @mdb-label-top-margin-base, @mdb-input-padding-base-vertical, @mdb-input-line-height-base, @mdb-label-as-placeholder-shim-base);

// default floating size/location with a form-group (need margin etc from a default form-group)
.form-group-size-variant(~".form-group", @mdb-input-font-size-base, @mdb-label-top-margin-base, @mdb-input-padding-base-vertical, @mdb-input-line-height-base, @mdb-label-as-placeholder-shim-base);

// sm floating size/location
.form-group-size-variant(~".form-group.form-group-sm", @mdb-input-font-size-small, @mdb-label-top-margin-small, @mdb-input-padding-small-vertical, @mdb-input-line-height-small, @mdb-label-as-placeholder-shim-small);

// lg floating size/location
.form-group-size-variant(~".form-group.form-group-lg", @mdb-input-font-size-large, @mdb-label-top-margin-large, @mdb-input-padding-large-vertical, @mdb-input-line-height-large, @mdb-label-as-placeholder-shim-large);


select.form-control {

  border: 0;
  box-shadow: none;
  border-radius: 0;

  .form-group.is-focused & {
    box-shadow: none;
    border-color: @mdb-input-underline-color;
  }

  &[multiple] {
    &,
    .form-group.is-focused & {
      height: 85px;
    }
  }
}

.input-group-button-variation(@vertical-padding) {
  .input-group-btn {
    .btn {
      margin: 0 0 @vertical-padding 0;
    }
  }
}

// ----------------
// input group/addon related styles

// default margin - no form-group required
.input-group-button-variation(@mdb-input-padding-base-vertical);

.form-group {
  //.form-control {
  //  float: none;
  //}

  // sm margin
  &.form-group-sm {
    .input-group-button-variation(@mdb-input-padding-small-vertical);
  }

  // lg margin
  &.form-group-lg {
    .input-group-button-variation(@mdb-input-padding-large-vertical);
  }
}

.input-group {  // may be in or outside of form-group
  .input-group-btn {
    padding: 0 12px; // match addon spacing
  }

  .input-group-addon {
    border: 0;
    background: transparent;
  }
}

// Input files - hide actual input - requires specific markup in the sample.
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
