.panel {
  border-radius: 2px;
  border: 0;

  .variations(~".panel", ~" > .panel-heading", background-color, @grey-200);
  .shadow-z-1;
}


[class*="panel-"] > .panel-heading {
  color: @mdb-text-color-light;
  border: 0;
}
.panel-default, .panel:not([class*="panel-"]) {
  > .panel-heading {
    color: @mdb-text-color-primary;
  }
}
.panel-footer {
  background-color: @grey-200;
}
