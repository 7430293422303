.cf-redesign {

  color: @cf-primary-text-color;

  & a {
    color: @cf-brand-primary;
  }

  .btn-primary:not(.btn-raised) {
    color: @cf-brand-theme;
  }

  .btn.btn-primary.btn-raised {
    background-color: @cf-brand-primary;

    &:hover,
    &:focus,
    &.active,
    &:active {
      background-color: lighten(@cf-brand-primary, 4%) !important;
    }

  }

  .alert {

    §-success {
      background-color: @cf-brand-success;
    }

    &-info {
      background-color: @cf-brand-info;
    }

    &-warning {
      background-color: @cf-brand-warning;
    }

    &-danger {
      background-color: @cf-brand-danger;
    }

  }

}