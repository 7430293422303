  .container {
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
  }
  .rbc-allday-cell {
  display: none;
  }
  .rbc-time-view {
  border: none;
  }
  .rbc-header {
  border-bottom: none;
  padding: 5px 0px;
  }
  .rbc-event-content{
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }
  .rbc-time-header-content, .rbc-time-content{
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #848484;
  }
  .rbc-header a {
    pointer-events: none;
    font-weight: normal;
  }
  .rbc-btn-group button{
    font-size: 13px;
    color: #848484;
  }