.dropdownjs::after {
  right: 5px;
  top: 3px;
  font-size: 25px;
  position: absolute;

  // bring in the material icon font and icon by code
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  content: "\e5c5"; // found here: https://github.com/google/material-design-icons/search?utf8=%E2%9C%93&q=arrow_drop_down

  pointer-events: none;
  color: #757575;
}
