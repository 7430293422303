// Placeholder text
.material-placeholder(@rules) {
  &::-moz-placeholder { @rules(); } // Firefox
  &:-ms-input-placeholder { @rules(); } // Internet Explorer 10+
  &::-webkit-input-placeholder  { @rules(); } // Safari and Chrome
}


// usage: .variations(~" .check", color, transparent);
.variations(@component, @selector-suffix, @property, @color-default) {
  .generic-variations(@component, @selector-suffix, @color-default, {
    @{property}: @variation-color;
  });
}



.background-variations(@component, @selector-suffix, @color-default) {
  .generic-variations(@component, @selector-suffix, @color-default, {
    background-color: @variation-color;
    & when (@variation-color = @mdb-btn-background-color) {
      color: @mdb-text-color-primary;
    }
    & when not (@variation-color = @mdb-btn-background-color) {
      color: @variation-color-text;
    }
  });
}

.text-variations(@component, @selector-suffix, @color-default) {
  .generic-variations(@component, @selector-suffix, @color-default, {
    color: @variation-color;
  });
}



//
// To use this mixin you should pass a function as final parameter to define
// the style. In that definition you can use the following variables to define it.
//
// @component: ~".btn" - one of the bs components that have -default, -danger, etc.
// @variation-color
// @variation-color-text
//
.generic-variations(@component, @selector-suffix, @color-default, @func) {
  @contrast-factor: 40%;
  // bootstrap styles
  &@{selector-suffix},
  &@{component}-default@{selector-suffix} {
    @variation-color: @color-default;
    @variation-color-text: @mdb-text-color-light;
    @func();
  }
  &@{component}-inverse@{selector-suffix} {
    @variation-color: @mdb-brand-inverse;
    //@variation-color-text: contrast(@mdb-brand-inverse, @mdb-text-color-primary, @mdb-text-color-light, @contrast-factor);
    @variation-color-text: contrast(@mdb-brand-inverse, @mdb-text-color-primary-hex, @mdb-text-color-light-hex, @contrast-factor);
    @func();
  }
  &@{component}-primary@{selector-suffix} {
    @variation-color: @brand-primary;
    @variation-color-text: @mdb-text-color-light;
    @func();
  }
  &@{component}-success@{selector-suffix} {
    @variation-color: @brand-success;
    @variation-color-text: @mdb-text-color-light;
    @func();
  }
  &@{component}-info@{selector-suffix} {
    @variation-color: @brand-info;
    @variation-color-text: @mdb-text-color-light;
    @func();
  }
  &@{component}-warning@{selector-suffix} {
    @variation-color: @brand-warning;
    @variation-color-text: @mdb-text-color-light;
    @func();
  }
  &@{component}-danger@{selector-suffix} {
    @variation-color: @brand-danger;
    @variation-color-text: @mdb-text-color-light;
    @func();
  }
}
