.alert {
    border: 0;
    border-radius: 0;

    // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
    .generic-variations(~".alert", ~"", @mdb-text-color-light, {
        background-color: @variation-color;
        color: @variation-color-text;

        a, .alert-link {
            color: @variation-color-text;
        }
    });

    &-info, &-danger, &-warning, &-success {
        color: @mdb-text-color-light;
    }

    &-default {
        a, .alert-link {
            color: @mdb-text-color-primary;
        }
    }
}
